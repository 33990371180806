
export default {
  name: 'ContainerWidth',
  props: {
    content: {
      type: Array,
    },
    classes: {
      type: String,
    },
  },
};
